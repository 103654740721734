// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Brad",
  middleName: "",
  lastName: "Webb",
  message: " Data Engineering | AWS | Traveler | Learner ",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/BradWebb101",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/brad-webb-101/",
    },
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/hashirshoaeb.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/headshot.png"),
  imageSize: 375,
  message:
    " I build AWS infrastructure around Data, to allow businesses to make better decisions. AWS CDK | Python and Typescript | Serverless. Currently living and working in Netherlands, enjoying Stroopwaffles and Windmills. Avid cyclist, Jiu Jitsu practitioner and Lasagne perfectionist. ",
  resume: "",
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: false,
  heading: "Recent GitHub Activity",
  gitHubUsername: "bradwebb101", //i.e."johnDoe12Gh"
  reposLength: 0,
  specificRepos: ["frenchconcession","portfolio_website_v2","geekle_smart_s3_bucket","smartS3BucketCsvValidation","spaAwsServerlessWebappBoilerPlate","s3BucketCreation"],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
  images: [
    { 
      img: require("../editable-stuff/hashirshoaeb.png"), 
      label: "First slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
    { 
      img: require("../editable-stuff/hashirshoaeb.png"), 
      label: "Second slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
  ],
  imageSize: {
    width:"615",
    height:"450"
  }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Capabilities",
  hardSkills: [
    { name: "\u2022 Python" },
    { name: "\u2022 Javascript & Typescript" },
    { name: "\u2022 Databases" },
    { name: "\u2022 Serverless" },
    { name: "\u2022 AWS" },
    { name: "\u2022 DevOps" },

  ],
  softSkills: [
    { name: "\u2022 AWS (Developer, Solutions Architect)" },
    { name: "\u2022 Masters of Applied Finance, QUT, 2018" },
    { name: "\u2022 Bachelors of Business: Finance, QUT, 2014" },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message1:
    "I am currently working at PostNL, building large enterprise scale serverless databases.",
  message2: "Very happy in my current position, and not looking for any oportunities right now",
  message3: 'To contact me, send me an email. ',
  email: "bradwebb101@outlook.com",
};

const experiences = {
  show: false,
  heading: "Experiences",
  data: [
    {
      role: 'Software Engineer',// Here Add Company Name
      companylogo: require('../assets/img/dell.png'),
      date: 'June 2018 – Present',
    },
    {
      role: 'Front-End Developer',
      companylogo: require('../assets/img/boeing.png'),
      date: 'May 2017 – May 2018',
    },
  ]
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences };
